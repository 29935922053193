import React, { useState, useEffect } from 'react';

import firebase from 'firebase/app';
import { Link } from "react-router-dom";

import tent from '../static/tentacle.png';
import '../styles/home.scss';

interface BlogLinkProps {
  id: string;
}

const BlogLink: React.FC<BlogLinkProps> = ({ id }) => (
  <li>
    <Link to={`/blog/${id}`}>
      {id}
    </Link>
  </li>
);

const Index = () => {
  const [quotes, setQuotes] = useState([]);

  useEffect(() => {
    const fetchQuotes = async () => {
      const db = firebase.firestore();
      const snapshot = await db.collection('quotes').doc('yP5vaFBDK18hsnoISY0B').get()
      const data = snapshot.data();
      if (data === undefined) {
        return;
      }

      setQuotes(data.quotes.reverse());
    };

    fetchQuotes();
  }, []);

  return quotes.length > 0 ? (
    <div className="home">
      <h1>My name PJ</h1>
      <div className="desc">
        <p>And I like to </p><Link to="/docking">dock</Link>
      </div>

      <div className="quote-wrapper">
        <h4>Some of my best quotes</h4>
        {quotes.map(q => (
          <div className="quote" key={q}>
            <p>{q}</p>
          </div>
        ))}
      </div>

      <ul>
        <BlogLink id="goose" />
        <BlogLink id="nj" />
      </ul>

      <div className="tent">
        <img alt="Tentacle" src={tent} />
      </div>
    </div>
  ) : null;
};

export default Index;
