import React from 'react';

import * as firebase from 'firebase/app';
import "firebase/analytics";
import "firebase/firestore";
import { Switch, Route } from "react-router-dom";

import Index from './pages/index';
import Blog from './pages/blog';
import Docking from './pages/docking';
import Iampj from './pages/Iampj';

const firebaseConfig = {
  apiKey: "AIzaSyBZv063zNzIqhwAy-utgS50-KOmIcQ_edY",
  authDomain: "bddev-317ef.firebaseapp.com",
  databaseURL: "https://bddev-317ef.firebaseio.com",
  projectId: "bddev-317ef",
  storageBucket: "bddev-317ef.appspot.com",
  messagingSenderId: "841330012783",
  appId: "1:841330012783:web:65f9829b8e009ac232139a",
  measurementId: "G-DN8D98SQ3C"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/docking">
          <Docking />
        </Route>
        <Route path="/blog/:id">
          <Blog />
        </Route>
        <Route path="/iampj">
          <Iampj />
        </Route>
        <Route path="/">
          <Index />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
