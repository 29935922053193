import React from 'react';
import { useParams } from 'react-router-dom';

import info from '../blog';
import '../styles/blog.scss';

interface EntryProps {
  date: string;
  text: string;
}

const Entry: React.FC<EntryProps> = ({ date, text }) => (
  <div>
    <h4>{date}</h4>
    <p>{text}</p>
  </div>
);

const Blog = () => {
  const { id } = useParams<{ id: string }>();
  const { name, description, entries } = info[id];

  return (
    <div className="blog">
      <h2>The personal, real life blog of {name}</h2>
      <p className="desc">{description}</p>

      <div className="entries">
        { entries.map(e => <Entry key={e.date} {...e} />) }
      </div>

      <style>{`
        h2 { margin-top: 50px; }
        .desc { font-size: 0.95em; }

        .entries {
          margin: 60px auto 0 auto;
          max-width: 760px;
          text-align: left;
        }
      `}</style>
    </div>
  );
}

export default Blog;