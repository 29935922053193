import React, { useEffect, useState } from 'react';

import firebase from 'firebase/app';

import './styles.scss';

const Iampj = () => {
  const [quote, setQuote] = useState('');
  const [db, setDb] = useState<null|firebase.firestore.Firestore>(null);

  useEffect(() => {
    setDb(firebase.firestore());
  }, []);

  const submitQuote = () => {
    if (quote === '' || db === null) {
      return;
    }

    db.collection('quotes').doc('yP5vaFBDK18hsnoISY0B').update({
      quotes: firebase.firestore.FieldValue.arrayUnion(quote),
    });
    setQuote('');
  }

  return (
    <div className="iampj">
      <h1>I am PJ</h1>
      <p>
        If you are PJ, or identify as PJ, then you can say whatever you want
        here and it will appear on the front page of quotes. Integrity is of
        the utmost importance, this form is for PJ only!
      </p>

      <div className="quote-form">
        <textarea
          value={quote}
          onChange={e => setQuote(e.target.value)}
          rows={10}
          cols={80}
        ></textarea>
        <button type="button" onClick={submitQuote}>Submit</button>
      </div>
    </div>
  );
};

export default Iampj;
