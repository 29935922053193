import { BlogProfile } from './types';

const goose: BlogProfile = {
  name: 'Grant',
  description: 'Grant GOOOOOOZGEEEEEE',
  entries: [
    {
      date: '4/11/2019',
      text: 'Does anyone know anything about network segmentation? I ' +
        'really need help understanding how I can add it to the neural ' +
        'net that\'s in my rechargable vibrating dildo. Trust me, once ' +
        'you try a dildo with a neural net, you NEVER go back',
    },
    {
      date: '4/04/2019',
      text: 'Yea, I\'d date an eighteen year old/senior in high school. ' +
        'There\'s nothing wrong with that',
    },
    {
      date: '1/25/2019',
      text: 'I love flexport more than I love my mom',
    }
  ]
};

export default goose;
