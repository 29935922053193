import goose from './goose';
import nj from './nj';
import { BlogProfile } from './types';

interface BlogLookup {
  [key: string]: BlogProfile;
}

const blog: BlogLookup = {
  goose,
  nj,
};

export default blog;
