import { BlogProfile } from './types';

const nj: BlogProfile = {
  name: 'Nathan',
  description: 'My name is NJ and I\'m 6\'4". Remember my name since I might forget it',
  entries: [
    {
      date: '5/22/2019',
      text: 'Its been 3 days since I arrived in Denver, though I cant remember anything ' +
        'due the legal status of cannibas. Every night my roommates remind me to turn ' +
        'off my lights and every morning I dream of Anna coming to wash my micropenis.'
    },
    {
      date: '12/05/18',
      text: 'I can\'t see my micropenis since i\'m so tall. That\'s why I need Anna ' +
        'to shower with me, so she can wash it',
    }
  ]
};

export default nj;
