import React from 'react';

const Docking = () => (
  <>
    <h1>What is Docking?</h1>

    <div className="def">
      <p>From </p><a href="https://www.urbandictionary.com/define.php?term=docking">Urban Dictionary</a><p>...</p>

      <div className="quote">
        <p>
          A very intimate and intense sexual act performed by two uncircumcised men. Guy
          one pulls his foreskin back and holds it while guy two stretches his open and
          outward as far as possible over the head and shaft of guy one's penis. Then guy
          one repeats the procedure as he's releasing all of his foreskin that he was
          holding back tightly, pulling the opening out and as far down as possible over
          guy two's penis, making sure guy two's foreskin stays in place. When complete
          both cockheads are touching tip to tip encased inside of each others foreskin,
          the duel coverage combined with the moist juices starting to flow from each of
          them creates a suctioning effect somewhat similar to a fleshlight, helping to
          hold everything in place while jacking both penises as one, an effect much like
          that of those chinese finger "handcuffs", allowing quite a bit of masturbating
          action while easily staying inside of each other. The sensation is so intense
          that both guys will quite often ejaculate simultaneously, visually filling the
          foreskin coupling with so much cum you can see it swell to the point of overflow,
          slowly uncoupling the foreskins and savoring the warm wet sensations, working
          their still hard penises together with their intermingled juices...
        </p>
      </div>
    </div>

    <style>{`
      h1 { margin-top: 50px; }

      .def {
        box-sizing: border-box;
        margin: 30px auto 0 auto;
        max-width: 600px;
        padding: 10px 20px;
        text-align: left;
      }

      .def > p, .def > a {
        display: inline;
      }

      .def > div {
        padding: 0px 20px;
        border-left: 3px solid rgb(51, 51, 51);
      }

      .def > div p {
        color: #f0c674;
      }
    `}</style>
  </>
);

export default Docking;
